import * as React from "react"
import { useState } from 'react';
import {
  Box,
  Button,
  Input,
  Textarea,
} from "@chakra-ui/react";

export default function MessageForm ({ sendMessage }) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const submitForm = (e) => {
    e.preventDefault();
    sendMessage({name, email, message});
  }

  return (
    <Box
      backgroundColor="gray.50"
      borderTop="1px"
      borderColor="gray.300"
      p={3}
    >
      <form onSubmit={submitForm} method="POST">
        <Input
          backgroundColor="white"
          mb={2}
          placeholder="Your Name"
          onInput={(e) => setName(e.target.value)}
          required={true}
        />
        <Input
          backgroundColor="white"
          mb={2}
          placeholder="Email Address"
          onInput={(e) => setEmail(e.target.value)}
          type='email'
          required={true}
        />
        <Textarea
          backgroundColor="white"
          placeholder="Your message ..."
          onInput={(e) => setMessage(e.target.value)}
          required={true}
        ></Textarea>
        <Box textAlign="right" pt={3}>
          <Button colorScheme="blue" type="submit">Send</Button>
        </Box>
      </form>
    </Box>
  );
}
