import * as React from "react";
import {
  Box,
  ChakraProvider,
} from "@chakra-ui/react";
import theme from "../../../chakra-theme";
import { Helmet } from 'react-helmet';

export default function Layout ({ children }) {
  const page_description = "Experience software engineer based in Birmingham, Al.";

  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Birmingham, Al based software engineer. | Michael Pitt</title>
        <meta name="description" content={page_description} />
        <meta property="og:title" content={`Michael Pitt | Birmingham, Al based software engineer.`} />
        <meta property="og:description" content={page_description} />
        <meta property="og:image" content="/img/open-graph.jpg" />
        <style type="text/css">{`
          * {
            text-rendering: optimizelegibility;
            -webkit-font-smoothing: auto !important;
          }
        `}</style>
      </Helmet>
      <Box
        py={{
          base : 6,
          md : 16
        }}
      >
        {children}
      </Box>
    </ChakraProvider>
  );
}
