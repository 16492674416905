import * as React from "react"
import {
  Box,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import TransformedDate from "../Atoms/TransformedDate";
import { Link as GatsbyLink } from "gatsby";


export default function Experience ({ company, company_link, position, from, to }) {
  const start = from ? new Date(from) : null;
  const end = to ? new Date(to) : null;

  return (
    <Flex alignItems="center" py={1}>
      <Heading
        as={GatsbyLink}
        to={company_link}
        color="blue.500"
        fontWeight="bold"
        fontSize="md" mr={3}
        _hover={{
          textDecoration: "underline"
        }}
      >
        {company}
      </Heading>
      <Box borderTop="2px" borderColor="gray.300" borderStyle="dotted"  flexGrow={1}/>
      <Text
        mx={{
          base : 0,
          md : 3
        }}
        display={{
          base : 'none',
          md : 'inline-block'
        }}
      >
        {position}
      </Text>
      <Text
        fontSize="sm"
        color="gray"
        ml={{
          base : 3,
          md : 0
        }}
      >
        <TransformedDate date={start} />&nbsp;&mdash;&nbsp;<TransformedDate date={end} />
      </Text>
    </Flex>
  );
}
