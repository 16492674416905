import * as React from "react"
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import Layout from "../components/layouts/Layout";
import Pill from "../components/Atoms/Pill";
import Messages from "../components/Organisms/Messages";
import Experience from "../components/Molecules/Experience";
import { Tooltip } from 'react-tooltip';

export default function IndexPage () {
  const skills = ['PHP', 'MySql', 'Laravel', 'Redis', 'AWS', 'Javascript', 'Vue.js', 'React.js', 'Ubuntu', 'CentOS', 'Redhat', 'HTML', 'CSS', 'SCSS', 'LESS', 'Git', 'Sketch', 'Figma', 'Illustrator', 'Photoshop'];
  const skillPills = skills.map((skill, idx) => <Pill key={idx} backgroundColor="gray.200" textColor="gray.700" mx={1} my={1} size="sm">{skill}</Pill>)

  return (
    <>
      <Layout>
        <Container maxW="2xl">
          <Pill
            backgroundColor="red.100"
            textColor="red.600"
            tooltipContent="Current freelance status"
            showDot={true}
            size="xs"
          >
            Not Available For Work
          </Pill>
          <Heading as="h1" fontSize="4xl" pt={3} pb={0} color="blue.500">Michael Pitt</Heading>
          <Heading as="h2" fontSize="xl" fontWeight="500" color="black" pb={3}>Senior Full-stack Engineer</Heading>
          <Text pb={4}>I'm a full-stack software engineer based out of Birmingham, Al. Along with 10+ years of experience building with web technologies, I've planned & designed marketing sites, intranets, extranets and web app UIs. I've also been known to thoughtfully craft branding for start-ups and medium sized companies.</Text>

          <Box pb={6}>
            <Heading as="h3" fontSize="lg" fontWeight="500" color="black" pt={3} pb={3}>Skills</Heading>
            <Flex
              flexWrap="wrap"
              mx={-1}
              my={-1}
            >
              {skillPills}
            </Flex>
          </Box>

          <Box pb={8}>
            <Heading as="h3" fontSize="lg" fontWeight="500" color="black" pt={3} pb={3}>Experience</Heading>
            <Box>
              <Experience company="NetVendor" company_link="https://www.netvendor.com" position="Senior Full-Stack Engineer" from="November 01, 2021" />
              <Experience company="Kinetic Communications" company_link="https://www.kinetic.com" position="Web Developer" from="September 01, 2017" to="November 01, 2021" />
              <Experience company="Scout Branding" company_link="https://www.scoutbrand.com" position="Interactive Developer" from="June 01, 2015" to="September 01, 2017" />
              <Experience company="Kinetic Communications" company_link="https://www.kinetic.com" position="Web Developer" from="May 01, 2013" to="June 01, 2015" />
              <Experience company="Kinetic Communications" company_link="https://www.kinetic.com" position="Web Developer Intern" from="October 01, 2011" to="May 01, 2013" />
            </Box>
          </Box>

          <Messages/>
        </Container>
      </Layout>
      <Tooltip id="tooltip"></Tooltip>
    </>
  );
}
