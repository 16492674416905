import * as React from "react"
import {
  Box,
  Text,
  Heading,
} from "@chakra-ui/react";

export default function Message ({ message }) {
  const messageStyling = {
    'system': {
      backgroundColor: 'gray.500',
      marginLeft: 0,
    },
    'guest': {
      backgroundColor: 'blue.500',
      marginLeft: 'auto',
    }
  };

  let messageStyle = messageStyling[message.originator];

  return (
    <Box
      backgroundColor={messageStyle.backgroundColor}
      borderRadius="xl"
      borderColor="gray.400"
      py={3}
      px={4}
      maxW="70%"
      marginLeft={messageStyle.marginLeft}
      my={3}
    >
      <Heading as="h4" fontSize="md" color="white" pb={1}>{message.name}</Heading>
      <Text color="white">{message.body}</Text>
    </Box>
  );
}
