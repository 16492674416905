import * as React from "react"
import {
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import MessageForm from "../Molecules/MessageForm";
import MessageThread from "../Molecules/MessageThread";
import Axios from "axios";
import { useState } from "react";

export default function Messages () {
  const [messages, setMessages] = useState([]);
  const [sendStatus, setSendStatus] = useState(null);

  const sendMessage = ({ name, email, message }) => {
    setSendStatus('sending');
    setMessages(oldArray => [...oldArray, {
      name: name,
      email: email,
      body: message,
      originator: 'guest'
    }]);

    Axios.post('/api/message', {
      name: name,
      email: email,
      message: message
    }).then((res) => {
      if (res.data.status === 'successful') {
        console.log('sent');
        setSendStatus('sent');
        setMessages(oldArray => [...oldArray, {
          name: 'System',
          body: 'Hi there, I\'ve received your message! I\'ll get back to you via email within 48 hours.',
          originator: 'system'
        }]);
      }
    }).catch(() => {
      setSendStatus(null);
      setMessages(oldArray => [...oldArray, {
        name: 'System',
        body: 'Sorry, there was an error. Please try sending me a message later.',
        originator: 'system'
      }]);
    });
  }

  let messagesBottom = <MessageForm sendMessage={sendMessage} />;

  if (sendStatus === 'sending') {
    messagesBottom = <Box align="center" backgroundColor="gray.50" borderTop="1px" borderColor="gray.300" p={3}>
      <Text color="blue.500">Sending message ...</Text>
    </Box>;
  }

  if (sendStatus === 'sent') {
    messagesBottom = <Box align="center" backgroundColor="green.50" borderTop="1px" borderColor="gray.300" p={3}>
      <Text color="green.500">Message Sent!</Text>
    </Box>;
  }

  return (
    <Box
      borderRadius="md"
      boxShadow="md"
      border="1px"
      borderColor="gray.300"
    >
      <Heading as="h3" fontSize="lg" color="gray.600" pl={6} py={3}>Message Me</Heading>
      <MessageThread messages={messages} />
      {messagesBottom}
    </Box>
  );
}
