import * as React from "react"
import {
  Box,
} from "@chakra-ui/react";

export default function Dot ({ textColor }) {

  return (
    <Box backgroundColor={textColor} borderRadius="100%" w="12px" h="12px" mr={1}></Box>
  );
}
