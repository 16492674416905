import * as React from "react"
import {
  Box,
} from "@chakra-ui/react";
import Message from "../Atoms/Message";

export default function MessageThread ({ messages }) {

  if (messages.length === 0) {
    return null;
  }

  let messagesInThread = messages.map((message, idx) =>
    <Message key={idx} message={message} />
  );

  return (
    <Box
      pb={3}
      px={6}
    >
      {messagesInThread}
    </Box>
  );
}
