import * as React from "react"
import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import Dot from "./Dot";


export default function Pill ({ children, tooltipContent, backgroundColor, textColor, showDot, mx, my, size }) {

  const dot = showDot ? <Dot textColor={textColor} /> : null;
  const marginX = mx ? mx : 0;
  const marginY = my ? my : 0;
  const fontSize = size ? size : 'md';

  return (
    <Box
      backgroundColor={backgroundColor}
      data-tooltip-id="tooltip"
      data-tooltip-content={tooltipContent}
      borderRadius="100px"
      py={1}
      px={4}
      display="inline-block"
      mx={marginX}
      my={marginY}
    >
      <Flex alignItems="center">
        {dot}
        <Text fontWeight="500" fontSize={fontSize} color={textColor}>{children}</Text>
      </Flex>
    </Box>
  );
}
